export const widgetDefaults = {
  pageName: 'booking_calendar_widget',
};

export enum CalendarErrors {
  SELECTED_SLOT_VALIDATION_NO_SELECTED_LOCATION = 'selected_slot_validation_no_selected_location',
  SELECTED_SLOT_VALIDATION_NO_SELECTED_DURATION = 'selected_slot_validation_no_selected_duration',
  SELECTED_SLOT_VALIDATION_NO_SELECTED_STAFF_MEMBER = 'selected_slot_validation_no_selected_staff_member',
  SELECTED_SLOT_VALIDATION_NO_TIME_SELECTED_ERROR = 'selected_slot_validation_no_time_selected_error',
  RESCHEDULE_SERVER_ERROR = 'reschedule_server_error',
  AVAILABLE_SLOTS_SERVER_ERROR = 'available_slots_server_error',
  NO_NEXT_AVAILABLE_DATE = 'next_available_date',
  NEXT_AVAILABLE_DATE_SERVER_ERROR = 'next_available_date_server_error',
}

export enum Preference {
  LOCATION = 'location',
  DURATION = 'duration',
  STAFF_MEMBER = 'staffMember',
}

export enum WidgetComponents {
  TIME_PICKER = 'TIME_PICKER',
  DATE_PICKER = 'DATE_PICKER',
  BOOKING_DETAILS = 'BOOKING_DETAILS',
  TIMEZONE_PICKER = 'TIMEZONE_PICKER',
  RESCHEDULE_DIALOG = 'RESCHEDULE_DIALOG',
  JOIN_WAITLIST_DIALOG = 'JOIN_WAITLIST_DIALOG',
  PREMIUM_VIEWER_DIALOG = 'PREMIUM_VIEWER_DIALOG',
  FILTER = 'FILTER',
}

export enum WidgetElements {
  TIME_SLOT = 'TIME_SLOT',
  MONTH_ARROW = 'MONTH_ARROW',
  DATE_IN_MONTH = 'DATE_IN_MONTH',
  GO_TO_NEXT_AVAILABLE_DATE_LINK = 'GO_TO_NEXT_AVAILABLE_DATE_LINK',
  CONFIRM_BUTTON = 'CONFIRM_BUTTON',
  DROPDOWN = 'DROPDOWN',
  CLOSE_BUTTON = 'CLOSE_BUTTON',
  CHECKBOX = 'CHECKBOX',
  COUNTER = 'COUNTER',
  CHECKBOX_ALL = 'CHECKBOX_ALL',
  FILTER_BUTTON = 'FILTER_BUTTON',
  CLEAR_BUTTON = 'CLEAR_BUTTON',
}

export enum TriggeredByOptions {
  INITIALIZE_WIDGET = 'INITIALIZE_WIDGET',
  TIMEZONE_CHANGED = 'TIMEZONE_CHANGED',
  DATE_SELECTED = 'DATE_SELECTED',
  MONTH_SELECTED = 'MONTH_SELECTED',
  BOOKING_DETAILS_CLEAR_BUTTON = 'BOOKING_DETAILS_CLEAR_BUTTON',
  STAFF_MEMBER_BOOKING_PREFERENCE_SELECTED = 'STAFF_MEMBER_BOOKING_PREFERENCE_SELECTED',
  LOCATION_BOOKING_PREFERENCE_SELECTED = 'LOCATION_BOOKING_PREFERENCE_SELECTED',
  BOOKING_DETAILS_BOOKING_PREFERENCE_SELECTED = 'BOOKING_DETAILS_BOOKING_PREFERENCE_SELECTED',
  GO_TO_NEXT_AVAILABLE_DATE_LINK = 'GO_TO_NEXT_AVAILABLE_DATE_LINK',
  TIME_SELECTED = 'TIME_SELECTED',
  SUBMIT = 'SUBMIT',
  FILTER_CHANGED = 'FILTER_CHANGED',
}

export enum InitializeCalendarDateOptions {
  FIRST_AVAILABLE_DATE = 'FIRST_AVAILABLE_DATE',
  TODAY = 'TODAY',
}

export type TimeSlotsAvailability = {
  AVAILABLE: number;
  FULL: number;
  TOO_LATE_TO_BOOK: number;
  TOO_EARLY_TO_BOOK: number;
  WAITLIST: number;
};

export const BOOKINGS_CALENDAR_REFERRAL_INFO = 'booking_calendar_widget';
