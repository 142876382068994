import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState, TFunction } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import {
  formtLocalDateTimeToDateView,
  getLocalTimezone,
} from '../../../../utils/dateAndTime/dateAndTime';
import {
  createNoAvailableSlotsViewModel,
  memoizedNoAvailableSlotsViewModel,
  NoAvailableSlotsViewModel,
} from '../noAvailableSlotsViewModel/noAvailableSlotsViewModel';
import {
  createTimeSelectionViewModel,
  memoizedTimeSelectionViewModel,
  TimeSelectionViewModel,
} from '../timeSelectionViewModel/timeSelectionViewModel';
import {
  createTimezoneSelectionViewModel,
  memoizedTimezoneSelectionViewModel,
  TimezoneSelectionViewModel,
} from '../timezoneSelectionViewModel/timezoneSelectionViewModel';
import {
  createTimePickerNotificationViewModel,
  memoizedTimePickerNotificationViewModel,
  TimePickerNotificationViewModel,
} from '../timePickerNotificationViewModel/timePickerNotificationViewModel';
import {
  getTimeSlotsAvailabilityStatuses,
  TimeSlotAvailabilityStatus,
} from '../../../../utils/timeSlots/timeSlots';
import { MemoizedViewModalFactory } from '../viewModel';

export enum TimePickerStatus {
  LOADING = 'LOADING',
  NO_AVAILABLE_SLOTS_FOR_SELECTED_DATE = 'NO_AVAILABLE_SLOTS_FOR_SELECTED_DATE',
  LOADED = 'LOADED',
}

export type TimePickerViewModel = {
  status: TimePickerStatus;
  formattedSelectedDate?: string;
  noAvailableSlotsViewModel: NoAvailableSlotsViewModel;
  timeSelectionViewModel: TimeSelectionViewModel;
  timezoneSelectionViewModel?: TimezoneSelectionViewModel;
  notificationViewModel?: TimePickerNotificationViewModel;
  accessibility: {
    onTimePickerLoadedAnnouncement: string;
    selectedDate: string;
  };
};

export const memoizedTimePickerViewModel: MemoizedViewModalFactory<TimePickerViewModel> = {
  extractDependencies: (factoryParams) => {
    const {
      selectedDate,
      timePickerStatus,
      availableSlots,
    } = factoryParams.state;
    return [
      selectedDate,
      timePickerStatus,
      availableSlots,
      ...memoizedNoAvailableSlotsViewModel.extractDependencies(factoryParams),
      ...memoizedTimeSelectionViewModel.extractDependencies(factoryParams),
      ...memoizedTimePickerNotificationViewModel.extractDependencies(
        factoryParams,
      ),
      ...memoizedTimezoneSelectionViewModel.extractDependencies(factoryParams),
    ];
  },
  createViewModel: createTimePickerViewModel,
};

export function createTimePickerViewModel({
  state,
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): TimePickerViewModel {
  // extract dependencies from inner view models
  const { selectedDate, timePickerStatus, availableSlots } = state;
  const { businessInfo, t } = context;

  const dateRegionalSettingsLocale = businessInfo.dateRegionalSettingsLocale;
  const formattedSelectedDate =
    selectedDate &&
    formtLocalDateTimeToDateView(selectedDate, dateRegionalSettingsLocale);

  const noAvailableSlotsViewModel: NoAvailableSlotsViewModel = createNoAvailableSlotsViewModel(
    {
      state,
      context,
    },
  );

  const timeSlotsAvailabilityStatuses: Map<
    string,
    TimeSlotAvailabilityStatus
  > = getTimeSlotsAvailabilityStatuses(availableSlots);

  const timeSelectionViewModel = createTimeSelectionViewModel({
    timeSlotsAvailabilityStatuses,
    state,
    context,
  });

  const notificationViewModel = createTimePickerNotificationViewModel({
    timeSlotsAvailabilityStatuses,
    state,
    context,
  });

  let timezoneSelectionViewModel: TimezoneSelectionViewModel | undefined;
  const shouldShowTimezoneSelection =
    selectedDate && getLocalTimezone() !== businessInfo.timeZone;
  if (shouldShowTimezoneSelection) {
    timezoneSelectionViewModel = createTimezoneSelectionViewModel({
      state,
      context,
    });
  }

  const accessibility = {
    onTimePickerLoadedAnnouncement: getAccessibleAnnouncement({
      timeSlotsAvailabilityStatuses,
      t,
    }),
    selectedDate: t('app.time-picker.accessibility.slots-in-selected-date', {
      date: formattedSelectedDate,
    }),
  };

  return {
    status: timePickerStatus,
    formattedSelectedDate,
    noAvailableSlotsViewModel,
    timeSelectionViewModel,
    ...(notificationViewModel ? { notificationViewModel } : {}),
    ...(timezoneSelectionViewModel ? { timezoneSelectionViewModel } : {}),
    accessibility,
  };
}

const getAccessibleAnnouncement = ({
  timeSlotsAvailabilityStatuses,
  t,
}: {
  timeSlotsAvailabilityStatuses: Map<string, TimeSlotAvailabilityStatus>;
  t: TFunction;
}): string => {
  let numberOfAvailableSlots = 0;
  timeSlotsAvailabilityStatuses.forEach(
    (status: TimeSlotAvailabilityStatus) => {
      if (
        !status.tooLateToBookAllSlots &&
        !status.tooEarlyToBookAllSlots &&
        !status.allSlotsAreFull
      ) {
        numberOfAvailableSlots++;
      }
    },
  );

  return numberOfAvailableSlots
    ? t('app.time-picker.accessibility.announcement.available-time-slots', {
        timeSlotsCount: numberOfAvailableSlots,
      })
    : t('app.time-picker.accessibility.announcement.no-available-time-slots');
};
