import { TimePickerNotificationType } from '../../ViewModel/timePickerNotificationViewModel/timePickerNotificationViewModel';
import { GoToNextAvailableDate } from '../goToNextAvailableDate/goToNextAvailableDate';

export type OnTimePickerNotificationCtaClick = (
  notificationType: TimePickerNotificationType,
) => void;

export function createOnTimePickerNotificationCtaClick(
  goToNextAvailableDate: GoToNextAvailableDate,
): OnTimePickerNotificationCtaClick {
  return (notificationType: TimePickerNotificationType) => {
    if (notificationType === TimePickerNotificationType.ALL_SESSIONS_ARE_FULL) {
      return goToNextAvailableDate();
    }
  };
}
