import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { TriggeredByOptions } from '../../../../utils/bi/consts';
import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';

export type SetSelectedMonth = (
  localDateTime: string,
  triggeredBy: TriggeredByOptions,
) => Promise<void>;

export function createSetSelectedMonthAction({
  getControllerState,
  context: { calendarApi, biLogger, settings },
}: ActionFactoryParams<CalendarState, CalendarContext>): SetSelectedMonth {
  return async (localDateTime: string, triggeredBy: TriggeredByOptions) => {
    const [state, setState] = getControllerState();
    const selectedMonth = localDateTime;
    setState({
      selectedMonth,
    });

    const availableSlotsPerDay = await calendarApi.getDateAvailability(
      selectedMonth,
      { state, settings },
    );

    setState({
      availableSlotsPerDay,
    });

    biLogger.bookingsCalendarDatePickerLoad({
      dateAvailability: JSON.stringify({
        datesWithAvailableSlots:
          availableSlotsPerDay?.availabilityEntries?.filter(
            (availabilityEntry: SlotAvailability) =>
              availabilityEntry.isBookable,
          ).length || 0,
        datesWithUnavailableSlots:
          availableSlotsPerDay?.availabilityEntries?.filter(
            (availabilityEntry: SlotAvailability) =>
              !availabilityEntry.isBookable,
          ).length || 0,
      }),
      triggeredBy,
    });
  };
}
