import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState, TFunction } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { getBookingPreferencesForSelectedTime } from '../../../../utils/bookingPreferences/bookingPreferencesForSelectedTime';
import { updateCalendarErrors } from './calendarErrorsHandler';
import {
  Slot,
  SlotAvailability,
} from '@wix/ambassador-availability-calendar/types';
import { Service, ServiceType, SlotDetails } from '@wix/bookings-uou-types';
import { GetActiveFeaturesResponse } from '@wix/ambassador-services-catalog-server/types';
import {
  BOOKINGS_CALENDAR_REFERRAL_INFO,
  CalendarErrors,
} from '../../../../utils/bi/consts';
import { CALENDAR_PAGE_URL_PATH_PARAM } from '../../../../api/CalendarApi';
import {
  DialogState,
  DialogType,
} from '../../ViewModel/dialogViewModel/dialogViewModel';
import { SelectedBookingPreference } from '../../../../utils/bookingPreferences/bookingPreferences';
import { getSlotDuration } from '../../../../utils/duration/duration';
import { SetFocusedElement } from '../setFocusedElement/setFocusedElement';
import { FlowElements } from '../../Hooks/useFlow';
import { isWaitingListFlow } from '../../../../utils/waitingList/waitingList';
import { Optional } from '../../../../types/types';
import { getSelectedValuesOfAllBookingPreferences } from '../../../../utils/selectedBookingPreferences/selectedBookingPreferences';
import { AddError } from '../addError/addError';

export const submitErrors = [
  CalendarErrors.SELECTED_SLOT_VALIDATION_NO_SELECTED_LOCATION,
  CalendarErrors.SELECTED_SLOT_VALIDATION_NO_SELECTED_DURATION,
  CalendarErrors.SELECTED_SLOT_VALIDATION_NO_SELECTED_STAFF_MEMBER,
  CalendarErrors.SELECTED_SLOT_VALIDATION_NO_TIME_SELECTED_ERROR,
];
export type OnSubmit = () => void;

function isBookingsEnabled(
  serviceType: ServiceType,
  activeFeatures: GetActiveFeaturesResponse,
): boolean {
  switch (serviceType) {
    case ServiceType.GROUP:
      return !!activeFeatures.applicableForGroups;
    case ServiceType.INDIVIDUAL:
      return !!activeFeatures.applicableForIndividual;
    default:
      return true;
  }
}

export function createOnSubmitAction(
  actionFactoryParams: ActionFactoryParams<CalendarState, CalendarContext>,
  addError: AddError,
  setFocusedElement: SetFocusedElement,
): OnSubmit {
  return async () => {
    const { getControllerState, context } = actionFactoryParams;
    const [state, setState] = getControllerState();
    const {
      t,
      businessInfo,
      biLogger,
      wixSdkAdapter,
      activeFeatures,
    } = context;
    const {
      selectedTime,
      selectableSlotsAtSelectedTime,
      selectedBookingPreferences,
      calendarErrors,
      selectedService,
    } = state;
    const isBookingEnabled = isBookingsEnabled(
      selectedService.info.type,
      activeFeatures,
    );

    if (!selectedTime) {
      const calendarError =
        CalendarErrors.SELECTED_SLOT_VALIDATION_NO_TIME_SELECTED_ERROR;

      void biLogger.bookingsPaymentMethodSelectionNextClicked({
        userMessage: calendarError,
      });
      addError(calendarError);
      setFocusedElement(FlowElements.DATE_TIME_NOTIFICATION);
    }

    if (selectableSlotsAtSelectedTime) {
      const bookingPreferences = getBookingPreferencesForSelectedTime({
        selectableSlotsAtSelectedTime,
        calendarErrors,
        selectedBookingPreferences,
        context,
      });

      updateCalendarErrors(
        bookingPreferences,
        addError,
        selectedBookingPreferences,
      );

      const isCalendarErrorsHasSubmitErrors = calendarErrors.some((error) =>
        submitErrors.includes(error),
      );

      const dateRegionalSettingsLocale = businessInfo.dateRegionalSettingsLocale!;
      const selectedSlots = getSelectedSlots({
        selectableSlotsAtSelectedTime,
        dateRegionalSettingsLocale,
        t,
        selectedBookingPreferences,
      });
      const isWaitingList = isWaitingListFlow({
        selectableSlots: selectableSlotsAtSelectedTime,
        selectedBookingPreferences,
        bookingPreferences,
      });

      if (!isCalendarErrorsHasSubmitErrors && selectedSlots.length > 0) {
        if (wixSdkAdapter.isPreviewMode()) {
          if (!isBookingEnabled) {
            await wixSdkAdapter.openPreviewPremiumModal(
              selectedService.info.type,
              BOOKINGS_CALENDAR_REFERRAL_INFO,
            );
          }
          return goToNextPage(
            actionFactoryParams,
            selectedSlots,
            isWaitingList,
          );
        }
        if (wixSdkAdapter.isSiteMode()) {
          if (isBookingEnabled) {
            return goToNextPage(
              actionFactoryParams,
              selectedSlots,
              isWaitingList,
            );
          } else {
            setState({
              dialog: {
                type: DialogType.PremiumViewer,
                state: DialogState.IDLE,
              },
            });
          }
        }
      } else {
        void biLogger.bookingsPaymentMethodSelectionNextClicked({
          selectedSlot: selectedTime,
          ...getBiProperties({ isWaitingList }),
        });
      }
    }
  };
}

export async function goToNextPage(
  actionFactoryParams: ActionFactoryParams<CalendarState, CalendarContext>,
  selectedSlots: SlotAvailability[],
  isWaitingList: boolean,
) {
  const {
    getControllerState,
    context: { biLogger, wixSdkAdapter },
  } = actionFactoryParams;
  const [state, setState] = getControllerState();
  const { selectedTime, selectedService, selectedTimezone } = state;
  const isRescheduling = !!state.rescheduleBookingDetails;
  void biLogger.bookingsContactInfoSaveSuccess({
    selectedSlot: selectedTime,
    ...getBiProperties({ isWaitingList }),
  });

  if (isRescheduling) {
    setState({
      dialog: {
        type: DialogType.RescheduleConfirm,
        state: DialogState.IDLE,
      },
    });
  } else if (isWaitingList) {
    setState({
      dialog: {
        type: DialogType.JoinWaitlist,
        state: DialogState.IDLE,
      },
    });
  } else {
    const slug = await wixSdkAdapter.getServiceSlug(
      CALENDAR_PAGE_URL_PATH_PARAM,
    );
    const slot = selectedSlots[0].slot;
    const slotDetails: SlotDetails = getSlotDetails({
      slug,
      slot,
      selectedService,
    });

    await wixSdkAdapter.navigateToBookingsContactInfoPage(
      slotDetails,
      BOOKINGS_CALENDAR_REFERRAL_INFO,
      selectedTimezone,
    );
  }
}

export const getSelectedSlots = ({
  selectableSlotsAtSelectedTime,
  dateRegionalSettingsLocale,
  t,
  selectedBookingPreferences,
}: {
  selectableSlotsAtSelectedTime: SlotAvailability[];
  dateRegionalSettingsLocale: string;
  t: TFunction;
  selectedBookingPreferences: SelectedBookingPreference[];
}): SlotAvailability[] => {
  const [
    selectedLocation,
    selectedStaffMember,
    selectedDuration,
  ] = getSelectedValuesOfAllBookingPreferences(selectedBookingPreferences);

  return selectableSlotsAtSelectedTime.filter(
    (selectableSlot: SlotAvailability) => {
      return (
        isSlotLocationSelected(selectableSlot?.slot, selectedLocation) &&
        isSlotStaffSelected(selectableSlot.slot, selectedStaffMember) &&
        isSlotDurationSelected(
          selectableSlot.slot,
          selectedDuration,
          dateRegionalSettingsLocale,
          t,
        )
      );
    },
  );
};

const isSlotLocationSelected = (
  slot: Optional<Slot>,
  selectedLocation: Optional<string>,
) => {
  const isSlotOnSelectedLocation =
    slot?.location?.id === selectedLocation ||
    slot?.location?.formattedAddress === selectedLocation;

  return selectedLocation ? isSlotOnSelectedLocation : true;
};

const isSlotStaffSelected = (
  slot: Optional<Slot>,
  selectedStaffMember: Optional<string>,
) => {
  const isSlotWithSelectedStaffMember =
    slot?.resource?.id === selectedStaffMember;
  return selectedStaffMember ? isSlotWithSelectedStaffMember : true;
};

const isSlotDurationSelected = (
  slot: Optional<Slot>,
  selectedDuration: Optional<string>,
  dateRegionalSettingsLocale: string,
  t: TFunction,
) => {
  const isSlotWithSelectedDuration =
    getSlotDuration({
      rfcStartTime: slot?.start!,
      rfcEndTime: slot?.end!,
      t,
      dateRegionalSettingsLocale,
    }).durationText === selectedDuration;
  return selectedDuration ? isSlotWithSelectedDuration : true;
};

const getSlotDetails = ({
  slug,
  slot,
  selectedService,
}: {
  slug: string;
  slot?: Slot;
  selectedService: Service;
}): SlotDetails => {
  const isClass = selectedService.info.type === ServiceType.GROUP;
  return {
    ...(isClass ? { id: slot?.id } : {}),
    slug,
    scheduleId: slot?.scheduleId!,
    start: new Date(slot?.start!).valueOf(),
    end: new Date(slot?.end!).valueOf(),
    staffMemberId: slot?.resource?.id!,
    locationId: slot?.location?.id ?? '',
  };
};

const getBiProperties = ({ isWaitingList }: { isWaitingList: boolean }) => {
  return isWaitingList
    ? { properties: JSON.stringify({ waitlist: true }) }
    : {};
};
