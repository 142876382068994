import { CalendarErrors } from '../../../../utils/bi/consts';
import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState, TFunction } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import settingsParams from '../../settingsParams';
import { FlowElements } from '../../Hooks/useFlow';
import { MemoizedViewModalFactory } from '../viewModel';

export enum BottomSectionStatus {
  LOADING = 'LOADING',
  LOADED = 'LOADED',
}

export type WidgetViewModel = {
  status: BottomSectionStatus;
  dateAndTimeSectionHeaderText: string;
  bookingDetailsSectionHeaderText: string;
  notificationText: string;
  focusedElement?: FlowElements;
};

export const memoizedWidgetViewModel: MemoizedViewModalFactory<WidgetViewModel> = {
  extractDependencies: ({
    state: { calendarErrors, focusedElement, bottomSectionStatus },
    context: { settings },
  }) => {
    const dateAndTimeSectionHeader = settings.get(
      settingsParams.dateAndTimeSectionHeader,
    );
    const bookingDetailsSectionHeader = settings.get(
      settingsParams.bookingDetailsSectionHeader,
    );
    return [
      calendarErrors,
      focusedElement,
      bottomSectionStatus,
      dateAndTimeSectionHeader,
      bookingDetailsSectionHeader,
    ];
  },
  createViewModel: createWidgetViewModel,
};

export function createWidgetViewModel({
  state,
  context: { getContent, t, settings },
}: ViewModelFactoryParams<CalendarState, CalendarContext>): WidgetViewModel {
  const { calendarErrors, focusedElement, bottomSectionStatus } = state;

  const notificationText = getNotificationText(calendarErrors, t);

  return {
    status: bottomSectionStatus,
    dateAndTimeSectionHeaderText: getContent({
      settingsParam: settingsParams.dateAndTimeSectionHeader,
      translationKey: 'app.settings.defaults.widget.date-and-time-header',
    }),
    bookingDetailsSectionHeaderText: getContent({
      settingsParam: settingsParams.bookingDetailsSectionHeader,
      translationKey: 'app.settings.defaults.widget.booking-details-header',
    }),
    notificationText,
    focusedElement,
  };
}

const getNotificationText = (
  calendarErrors: CalendarErrors[],
  t: TFunction,
) => {
  if (
    calendarErrors.includes(
      CalendarErrors.SELECTED_SLOT_VALIDATION_NO_TIME_SELECTED_ERROR,
    )
  ) {
    return t('app.notification.no-time-selected-error.text');
  }
  return '';
};
