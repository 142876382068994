import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { FlowElements } from '../../Hooks/useFlow';

export type SetFocusedElement = (flowElement: FlowElements) => void;

export function createSetFocusedElementAction({
  getControllerState,
}: ActionFactoryParams<CalendarState, CalendarContext>): SetFocusedElement {
  return (flowElement) => {
    const [, setState] = getControllerState();
    setState({
      focusedElement: flowElement,
    });
    setState({
      focusedElement: undefined,
    });
  };
}
