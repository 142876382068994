import {
  createDummyBookingDetailsViewModel,
  BookingDetailsViewModel,
  memoizedBookingDetailsViewModel,
} from './bookingDetailsViewModel/bookingDetailsViewModel';
import { CalendarState } from '../controller';
import { ViewModelFactoryParams } from '../../../utils/ControlledComponent/ControlledComponent.types';
import {
  HeaderViewModel,
  memoizedHeaderViewModel,
} from './headerViewModel/headerViewModel';
import { CalendarContext } from '../../../utils/context/contextFactory';
import {
  memoizedWidgetViewModel,
  WidgetViewModel,
} from './widgetViewModel/widgetViewModel';
import {
  TimePickerViewModel,
  memoizedTimePickerViewModel,
} from './timePickerViewModel/timePickerViewModel';
import {
  DatePickerViewModel,
  memoizedDatePickerViewModel,
} from './datePickerViewModel/datePickerViewModel';
import {
  memoizedRescheduleDetailsViewModel,
  RescheduleDetailsViewModel,
} from './rescheduleDetailsViewModel/rescheduleDetailsViewModel';
import {
  DialogViewModel,
  memoizedDialogViewModel,
} from './dialogViewModel/dialogViewModel';
import {
  memoizedToastViewModel,
  ToastViewModel,
} from './toastViewModel/toastViewModel';
import { EmptyStateViewModel } from './emptyStateViewModel/emptyStateViewModel';
import isDeepEqual from 'fast-deep-equal';

export type CalendarViewModel = {
  widgetViewModel: WidgetViewModel;
  headerViewModel: HeaderViewModel;
  rescheduleDetailsViewModel?: RescheduleDetailsViewModel;
  timePickerViewModel: TimePickerViewModel;
  datePickerViewModel: DatePickerViewModel;
  bookingDetailsViewModel: BookingDetailsViewModel;
  dialogViewModel: DialogViewModel;
  toastViewModel: ToastViewModel;
  emptyStateViewModel?: EmptyStateViewModel;
};

const memo = async (
  func: (() => any) | (() => Promise<any>),
  dependencies: any[],
  memoizationTable: MemoizationTable,
  key: string,
) => {
  if (
    !memoizationTable[key] ||
    !isDeepEqual(memoizationTable[key].prevDeps, dependencies)
  ) {
    memoizationTable[key] = {
      prevDeps: dependencies,
    };
    return func();
  }
};

export type CalendarViewModelFactoryParams = ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>;

export type MemoizedViewModalFactory<ViewModelType> = {
  createViewModel?: (
    factoryParams: CalendarViewModelFactoryParams,
  ) => ViewModelType | Promise<ViewModelType>;
  extractDependencies: (factoryParams: CalendarViewModelFactoryParams) => any[];
};

type MemoizationTable = {
  [key: string]: {
    prevDeps: any[];
  };
};

type ViewModelNames = keyof CalendarViewModel;

const createMemoizedViewModel = async (
  viewModelFactories: {
    [key: string]: MemoizedViewModalFactory<ViewModelNames>;
  },
  viewModelFactoryParams: CalendarViewModelFactoryParams,
  memoizationTable: MemoizationTable,
) => {
  const partialViewModel: CalendarViewModel = {} as CalendarViewModel;

  for await (const [key, viewModelFactory] of Object.entries(
    viewModelFactories,
  )) {
    const viewModelName = key as keyof CalendarViewModel;
    const viewModelChunk = await memo(
      () => viewModelFactory?.createViewModel?.(viewModelFactoryParams),
      viewModelFactory.extractDependencies(viewModelFactoryParams),
      memoizationTable,
      viewModelName,
    );
    if (viewModelChunk) {
      partialViewModel[viewModelName] = viewModelChunk;
    }
  }

  return partialViewModel;
};

export function createMemoizedCalendarViewModelFactory(isDummy = false) {
  const memoizationTable: MemoizationTable = {};

  return async (viewModelFactoryParams: CalendarViewModelFactoryParams) => {
    return isDummy
      ? createDummyCalendarViewModel(viewModelFactoryParams, memoizationTable)
      : createCalendarViewModel(viewModelFactoryParams, memoizationTable);
  };
}

export async function createCalendarViewModel(
  viewModelFactoryParams: CalendarViewModelFactoryParams,
  memoizationTable: MemoizationTable = {},
): Promise<CalendarViewModel> {
  const viewModelFactories: { [key: string]: MemoizedViewModalFactory<any> } = {
    widgetViewModel: memoizedWidgetViewModel,
    headerViewModel: memoizedHeaderViewModel,
    rescheduleDetailsViewModel: memoizedRescheduleDetailsViewModel,
    timePickerViewModel: memoizedTimePickerViewModel,
    datePickerViewModel: memoizedDatePickerViewModel,
    bookingDetailsViewModel: memoizedBookingDetailsViewModel,
    dialogViewModel: memoizedDialogViewModel,
    toastViewModel: memoizedToastViewModel,
  };

  return createMemoizedViewModel(
    viewModelFactories,
    viewModelFactoryParams,
    memoizationTable,
  );
}

export async function createDummyCalendarViewModel(
  viewModelFactoryParams: ViewModelFactoryParams<
    CalendarState,
    CalendarContext
  >,
  memoizationTable: MemoizationTable = {},
): Promise<CalendarViewModel> {
  const calendarViewModel = await createCalendarViewModel(
    viewModelFactoryParams,
    memoizationTable,
  );
  calendarViewModel.bookingDetailsViewModel = createDummyBookingDetailsViewModel(
    viewModelFactoryParams,
  );
  return calendarViewModel;
}
