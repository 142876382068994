import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { SetSelectedMonth } from '../setSelectedMonth/setSelectedMonth';
import { TriggeredByOptions } from '../../../../utils/bi/consts';
import { SetSelectedDate } from '../setSelectedDate/setSelectedDate';

export type RefetchPlatformData = (
  triggeredBy: TriggeredByOptions,
) => Promise<void>;

export function createRefetchPlatformDataAction(
  { getControllerState }: ActionFactoryParams<CalendarState, CalendarContext>,
  setSelectedDate: SetSelectedDate,
  setSelectedMonth: SetSelectedMonth,
): RefetchPlatformData {
  return async (triggeredBy) => {
    const [state] = getControllerState();
    await Promise.all([
      setSelectedDate(state.selectedDate!, triggeredBy),
      setSelectedMonth(state.selectedMonth!, triggeredBy),
    ]);
  };
}
