import { DialogType } from '../../components/BookingCalendar/ViewModel/dialogViewModel/dialogViewModel';
import { WidgetComponents } from './consts';
import { Optional } from '../../types/types';

export const mapDialogTypeToWidgetComponent = (
  dialogType: Optional<DialogType>,
): Optional<WidgetComponents> => {
  switch (dialogType) {
    case DialogType.PremiumViewer:
      return WidgetComponents.PREMIUM_VIEWER_DIALOG;
    case DialogType.JoinWaitlist:
      return WidgetComponents.JOIN_WAITLIST_DIALOG;
    case DialogType.RescheduleConfirm:
      return WidgetComponents.RESCHEDULE_DIALOG;
  }
};
