import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { isSlotWithOpenWaitingList } from '../../../../utils/timeSlots/timeSlots';

export type SetSelectedTime = (selectedTime: string | undefined) => void;

export function createSetSelectedTimeAction({
  getControllerState,
}: ActionFactoryParams<CalendarState, CalendarContext>): SetSelectedTime {
  return async (selectedTime: string | undefined) => {
    const [state, setState] = getControllerState();
    const { availableSlots } = state;

    let selectableSlots;
    if (selectedTime) {
      selectableSlots = availableSlots?.availabilityEntries?.filter(
        (availableSlot) => {
          return (
            availableSlot.slot?.start === selectedTime &&
            (availableSlot.isBookable ||
              isSlotWithOpenWaitingList(availableSlot))
          );
        },
      );
    }

    setState({
      calendarErrors: [],
      selectedBookingPreferences: [],
      selectableSlotsAtSelectedTime: selectableSlots ?? [],
      selectedTime,
    });
  };
}
